body {
  margin: 0;
  padding: 0;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
::-webkit-scrollbar {
  display: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.2);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.2);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.2);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.2);
}

html {
  font-family: "Roboto", sans-serif;
}

@keyframes circleFill {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes circleUnfill {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
  }
}

@keyframes circleErrorSway {
  0% {
    -webkit-transform: translateX(0px);
  }
  25% {
    -webkit-transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(0px);
  }
  75% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0px);
  }
}

@keyframes circleCorrectSway {
  0% {
    -webkit-transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(0px);
  }
}

/* Handle next page animations */
.next-enter {
  transform: translateX(100vw);
}

.next-enter.next-enter-active {
  box-shadow: rgba(0, 0, 0, 0.1) -10px 0px 20px 10px;
  z-index: 2;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.next-exit {
  transform: translateX(0);
}

.next-exit.next-exit-active {
  z-index: 1;
  transform: translateX(-100px);
  transition: transform 300ms ease-in-out;
}

/* Handle previous page animations */
.previous-enter {
  transform: translateX(-100vw);
}

.previous-enter.previous-enter-active {
  box-shadow: rgba(0, 0, 0, 0.1) -10px 0px 20px 10px;
  z-index: 2;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.previous-exit {
  transform: translateX(0);
}

.previous-exit.previous-exit-active {
  z-index: 1;
  transform: translateX(100px);
  transition: transform 300ms ease-in-out;
}
